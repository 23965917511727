/**
 *    @desc:       Custom JS file
 *    @author:     stuart.cole@gmail.com
 *    @url:        http://www.identidot.com
 *    @date:       1st June 2016
 *    @license:    You are free to use any of the code within, but please make sure this header is included
 */
/*
 * function to allow for localhost use with sitename if in local file rather than own root.
 * Allows use of HTTP or HTTPS
 */
var string = location.hostname;
var substring = "localhost";
var site = "assetmanager/public"; // enter the base HTML route to be appended in local environment
var pos = (string.indexOf(substring));
if (pos > -1) {
    // to be used in test / local environment
    var sitename = location.protocol + '//' + location.hostname + '/' + site + '/';
}
else {
    // route for use when in live environment
    var sitename = location.protocol + '//' + location.hostname + '/';
}
$(document).ready(function () {
    /*
     * Small function to allow for back to top animation based on jQuery
     */
    $('a.backToTop').click(function () {
        $('html, body').animate({scrollTop: '0px'}, 1500);
    });
    /*
     * instantiate the use of specific DatePicker functions throughout the site
     */

    $("#datepicker").datepicker({
        changeMonth: true,
        changeYear: true
    });
    $("body").on("click", ".datepicker", function () {
        $(this).datepicker({
            changeMonth: true,
            changeYear: true,
            dateFormat: 'dd-mm-yy'
        });
        $(this).datepicker("show");
    });

    $('.date').datepicker({
        format: 'mm-dd-yyyy'
    });

    $(".nav-tabs a[data-toggle=tab]").on("click", function (e) {
        if ($(this).hasClass("disabled")) {
            alert("one");
            e.preventDefault();
            return false;
        }
    });
});
function alerting(val) {
    alert(val);
}
function checkToken(data) {
    if (data.match(/TokenMismatchException/)) {
        location.reload();
    } // to use checkToken()
}
function hideAssets() {
    $("#assetsOut").slideUp();
    $("#assetsList").slideUp();
}
function showAssetArea() {
    $("#assetsOut").slideUp();
    $("#assetsList").slideDown();
    $("#editOut").slideUp();
    $("#assetsArea").slideDown();
}
function showAddAsset() {
    $.post('showAsset', {queryString: "go"}, function (data) {
        checkToken(data);
        $("#assetsOut").html(data);
        $("#assetsOut").slideDown();
        $("#assetsArea").slideUp();
    });
}
function showAssets() {
    $.post('assets', {queryString: "go"}, function (data) {
        checkToken(data);
        $("#assetsArea").html(data);
    });
    showAssetArea();
}
function showAssetsList() {
    $("#assetsOut").slideUp();
    $("#assetsArea").slideDown();
}
function showSS(type) {
    $.post('show' + type, {queryString: "123"}, function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
        hideAssets();
    });
}
function addSS(type) {
    var an = $("#name").val().trim();
    if (an === "" || an === null) {
        $("#serviceadd").addClass("has-error");
        $("#error-block").fadeIn();
        $("#error-block").html("<b>You need to enter something above.</b>");
    }
    else {
        $("#error-block").fadeOut();
        $("#error-block").html("");

        $.post('add' + type, $("#add" + type + "Form").serialize(), function (data) {
            checkToken(data);
            $("#editOut").slideDown();
            $("#editIn").html(data);
            $("#serviceadd").removeClass("has-error");
        });
    }
}
function deleteArtist(aid) {
    $.post('deleteArtist/' + aid, $("#deleteArtistForm" + aid).serialize(), function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
    });
}
function deleteLocation(aid) {

    $.post('deleteLocation/' + aid, $("#deleteLocationForm" + aid).serialize(), function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
    });
}
function deleteProperty(aid) {

    $.post('deleteProperty/' + aid, $("#deletePropertyForm" + aid).serialize(), function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
    });
}
function deleteCategory(aid) {
    $.post('deleteCategory/' + aid, $("#deleteCategoryForm" + aid).serialize(), function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
    });
}
function deleteOwner(aid) {
    $.post('deleteOwner/' + aid, $("#deleteOwnerForm" + aid).serialize(), function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
    });
}
function deleteInsurer(aid) {
    $.post('deleteInsurer/' + aid, $("#deleteInsurerForm" + aid).serialize(), function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
    });
}
function deleteValuer(aid) {
    $.post('deleteValuer/' + aid, $("#deleteValuerForm" + aid).serialize(), function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
    });
}
function deleteBroker(aid) {
    $.post('deleteBroker/' + aid, $("#deleteBrokerForm" + aid).serialize(), function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
    });
}
function updateValues() {
    var err = 0;
    var an = $("#category").find(":selected").val();
    if (an === "" || an === null || an == undefined || an === '-1') {
        $("#catSelect").addClass("has-error");
        $("#cat-error-block").fadeIn();
        $("#cat-error-block").html("<b>You need to choose a category, or all assets.</b>");
        err = err + 1;
    }
    else {
        $("#catSelect").removeClass("has-error");
        $("#cat-error-block").fadeOut();
        $("#cat-error-block").html("");

    }
    var value = $("#value").val();
    if (value === '' || value === null || value == 0 || value == undefined) {
        $("#insValue").addClass("has-error");
        $("#value-error-block").fadeIn();
        $("#value-error-block").html("<b>Enter a value.</b>");
        err = err + 1;
    }
    else {
        $("#insValue").removeClass("has-error");
        $("#value-error-block").fadeOut();
        $("#value-error-block").html("");
    }
    var direction = $("#direction").find(":selected").val();
    if (direction === '-1' || direction === '' || value == undefined) {

        $("#directionSelect").addClass("has-error");
        $("#direction-error-block").fadeIn();
        $("#direction-error-block").html("<b>Please choose how the values should change.</b>");
        err = err + 1;
    }
    else {
        $("#directionSelect").removeClass("has-error");
        $("#direction-error-block").fadeOut();
        $("#direction-error-block").html("");
    }
    var nom = $("#updateName").val();
    if (nom === '' || direction === null || nom == undefined) {

        $("#updateNameDiv").addClass("has-error");
        $("#updateName-error-block").fadeIn();
        $("#updateName-error-block").html("<b>Enter a reference for this amend.</b>");
        err = err + 1;
    }
    else {
        $("#updateNameDiv").removeClass("has-error");
        $("#updateName-error-block").fadeOut();
        $("#updateName-error-block").html("");
    }
    if (err === 0) {

        $.post('updateValues', $("#amendValuesForm").serialize(), function (data) {
            $("#editOut").slideDown();
            $("#editIn").html(data);
        });
    }

}
function showPage(page) {
    $.post('showPage/' + page, {queryString: "" + page + ""}, function (data) {
        checkToken(data);
        $("#editOut").slideDown();
        $("#editIn").html(data);
        hideAssets();
    });
}
function showAssetInDetail(id) {
    $.post('showAsset/' + id, {queryString: "go"}, function (data) {
        checkToken(data);
        $("#assetsOut").html(data);
        $("#assetsOut").slideDown();
        $("#assetsArea").slideUp();
    });
}
function selectTab(tab) {
    $("#assetDetails").removeClass("active");
    $("#asset").removeClass("active in");
    $("#codingDetails").removeClass("active");
    $("#coding").removeClass("active in");
    $("#financialDetails").removeClass("active");
    $("#financial").removeClass("active in");
    $("#insuranceDetails").removeClass("active");
    $("#insurance").removeClass("active in");
    $("#historyDetails").removeClass("active");
    $("#history").removeClass("active in");
    $("#theftDetails").removeClass("active");
    $("#theft").removeClass("active in");
    $("#documentDetails").removeClass("active");
    $("#document").removeClass("active in");
    $("#" + tab + "Details").addClass("active");
    $("#" + tab + "").addClass("active in");
}
function amendAsset(tab) {
    if (tab === '' || tab === null) {
        tab = 'asset';
    }
    $.post('amendAsset', $("#amendAssetForm").serialize(), function (data) {
        checkToken(data);
        $("#assetsOut").html(data);

        $.post('assets', {queryString: "go"}, function (data) {
            checkToken(data);
            $("#assetsArea").html(data);
        });
        selectTab(tab);
    });
}
function browseAssets() {
    //alert('browsing');
    $.post('browseAssets', {queryString: "go"}, function (data) {
        //   alert(data);
        $("#assetsArea").html(data);
    });
    showAssetArea();
}
function filter() {
    $.post('filterAssets', $("#filterAssetForm").serialize(), function (data) {
        checkToken(data);
        $("#assetsArea").html(data);
        // $.post('assets', {queryString: "go"}, function (data) {
        //     $("#assetsArea").html(data);
        // })
    });
}
function filterReport(type) {
    $.post('filterReport/' + type + "?tp=" + type, $("#filterAssetForm").serialize(), function (data) {
        checkToken(data);
        //$.post('filterReport/'+type+"?tp="+type, {type: type}, function (data) {
        $("#assetsArea").html(data);
    });
    //showAssetArea();
}
function summaryAssets() {
    $.post('summaryAssets', {queryString: 'go'}, function (data) {
        checkToken(data);
        $("#assetsArea").html(data);
        showAssetArea();
        // $.post('assets', {queryString: "go"}, function (data) {
        //     $("#assetsArea").html(data);
        // })
    });
}
function assetGrid() {    $.post('assetGrid', {queryString: 'go'},
    function (data) {
        checkToken(data);
        $("#innerApp").html(data);
        showAssetArea();
    });
}
function assetGrid2() {
    $.post('assetGrid/', {queryString: 'go'}, function (data) {
        checkToken(data);
        $("#innerApp").html(data);
        showAssetArea();
    });
}
function updateAsset(asset,field,value){
    $.post('updateAsset',{ id:asset, field:field, value:value}, function(data){
        addUpdated(field+"-"+asset);
        removeUpdated(field+"-"+asset);
    })
}
function showExempt(type) {
    if (type == 'yes') {
        $("#asset-exempt-date").prop("disabled", false);
    }
    if (type == 'no') {
        $("#asset-exempt-date").prop("disabled", true);
        $("#asset-exempt-date").val('');
    }
}
function uploadDocs(aid) {
    var valid = [
        ".doc",
        ".docm",
        ".docx",
        ".odt",
        ".rtf",
        ".txt",
        ".xml",
        ".xps",
        ".pdf",
        ".csv",
        ".xls",
        ".xlsx",
        ".xlsm",
        ".ods",
        ".odp",
        ".pps",
        ".ppsm",
        ".ppsx",
        ".ppt",
        ".pptm",
        ".pptx",
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".wmv",
        ".avi",
        ".mp4",
        ".mp3",
    ];
    if (validateFile(upDocForm, valid)) {
        var ins = document.getElementById('file').files.length;
        if (ins === 0) {
            $("#upFormError").fadeIn();
            $("#upFormError").html("You need to add a file to upload");
        }
        else {

            var formData = new FormData();
            formData.append("aid", aid);
            formData.append("_token", $("#_token").val());
            for (var x = 0; x < ins; x++) {
                formData.append("file[]", document.getElementById('file').files[x]);
            }
            $.ajax({
                type: 'POST',
                url: 'uploadDocs/' + aid,
                data: formData,
                contentType: false,
                processData: false,
                success: function (data) {
                    checkToken(data);
                    $("#assetsOut").html(data);
                    selectTab('document');
                }
            });
        }
    }
}
function uploadImages(aid) {
    var valid = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
    if (validateFile(upImageForm, valid)) {
        var ins = document.getElementById('image').files.length;
        if (ins === 0) {
            $("#upFormError").fadeIn();
            $("#upFormError").html("You need to add a file to upload");
        }
        else {
            var formData = new FormData();
            formData.append("aid", aid);
            formData.append("_token", $("#_token").val());
            for (var x = 0; x < ins; x++) {
                formData.append("file[]", document.getElementById('image').files[x]);
            }
            $.ajax({
                type: 'POST',
                url: 'uploadImages/' + aid,
                data: formData,
                contentType: false,
                processData: false,
                success: function (data) {
                    checkToken(data);
                    //$("#imageThumbs").html(data);
                    $("#assetsOut").html(data);
                }
            });
        }
    }
}
function validateFile(oForm, _validFileExtensions) {
    var arrInputs = oForm.getElementsByTagName("input");
    for (var i = 0; i < arrInputs.length; i++) {
        var oInput = arrInputs[i];
        if (oInput.type == "file") {
            var sFileName = oInput.value;
            if (sFileName.length > 0) {
                var blnValid = false;
                for (var j = 0; j < _validFileExtensions.length; j++) {
                    var sCurExtension = _validFileExtensions[j];
                    if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                        blnValid = true;
                        break;
                    }
                }
                if (!blnValid) {
                    $("#errorsArea").html('<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">' +
                        '<span aria-hidden=\"true\">&times;</span></button>Sorry, ' + sFileName + ' is invalid, allowed extensions are: ' + _validFileExtensions.join(", ") + '</div>'
                    );
                    return false;
                }
            }
        }
    }
    return true;
}
function showReport(type) {
    $.post('reports/' + type + "?tp=" + type, {type: type}, function (data) {
        checkToken(data);
        $("#assetsArea").html(data);
    });
    showAssetArea();
}
function showUpload() {
    $("#csvupload").fadeIn();
}
function useNewLogin() {
    $("#newLoginForm").fadeIn();
}
function reportDownload(type) {
    $("#spinner").fadeIn();
    $.post('downloadReport/' + type + "?tp=" + type, $("#filterAssetForm").serialize(), function (data) {
        $("#assetsArea").html(data);
    });
    showAssetArea();
}
function printCertificates() {
    $("#spinner").fadeIn();
    $.post('certificates', {go: 'go'}, function (data) {
        checkToken(data);
        $("#assetsArea").html(data);
    });
    showAssetArea();
}
function certificateDownload() {
    $("#spinner").fadeIn();
    $.post('downloadCertificates', {go: 'go'}, function (data) {
        checkToken(data);
        $("#assetsArea").html(data);
    });
    showAssetArea();
}
function makeMainImage(aid, image) {
    $.post('makeMainImage', {id: '' + aid + '', key_image: '' + image + ''}, function (data) {
        checkToken(data);

        $("#assetsOut").html(data);
        $("#assetsOut").slideDown();
        $("#assetsArea").slideUp();
    });
    showAssetArea();
}
function removeImage(aid, image) {
    $.post('removeImage', {id: '' + aid + '', key_image: '' + image + ''}, function (data) {
        checkToken(data);

        $("#assetsOut").html(data);
        $("#assetsOut").slideDown();
        $("#assetsArea").slideUp();
    });
    showAssetArea();
}
function impersonate(userid) {
    $.post('setImpersonate/1?uid=' + userid, function (data) {
        if (data.match(/User Set/)) {
            location.reload(true);
        }

    });
    $("#assetsArea").html(data);

}
function stopImpersonate() {
    $.post('unsetImpersonate/', function (data) {
        if (data.match(/Unset/)) {
            location.reload(true);
        }
        alert(data);
        $("#assetsArea").html(data);
    });
    //location.reload(true);
}
function setCookie(name, value, expires, path, domain, secure) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expires);
    document.cookie = name + "=" + escape(value) +
        ((expires === null) ? "" : "; expires=" + exdate) +
        ((path === null) ? "; path=/" : "; path=" + path) +
        ((domain === null) ? "" : "; domain=" + domain) +
        ((secure === null) ? "" : "; secure");
}
function numbersOnly(myfield, e, dec) {
    var key;
    var keychar;

    if (window.event)
        key = window.event.keyCode;
    else if (e)
        key = e.which;
    else
        return true;
    keychar = String.fromCharCode(key);

// control keys
    if ((key == null) || (key == 0) || (key == 8) ||
        (key == 9) || (key == 13) || (key == 27))
        return true;

// numbers
    else if ((("0123456789.").indexOf(keychar) > -1))
        return true;

    else
        return false;
}
function addUpdated(id){
    $("#"+id).addClass("updated");
}
function removeUpdated(id){
    setTimeout(function(){
        $("#"+id).removeClass("updated");
    },2000);
}
function deleteAsset(id){
    if(confirm("This will permanently delete this asset, as you sure you wish to continue?")){
        $.post('deleteAsset/'+id+'', {queryString: id}, function (data) {
            checkToken(data);
            $("#assetsArea").html(data);
        });
        showAssetArea();
    }
}